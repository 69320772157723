import { Box, Checkbox, Flex, Icon, Text } from '@chakra-ui/react'
import colors from '../../config/theme/colors'
import CashRegister from '../Icon/CashRegister'
import CashIcon from '../Icon/CashIcon'
import Description from '../Icon/Description'
import Grid from '../Icon/Grid'
import useNTabs from './useNTabs'
import useStorage from '../../hooks/useStorage'
import Delivery from '../Icon/Delivery'
import Table from '../Icon/Table'

const displayLabels = { base: 'none', md: 'block' }

export default function NTabs({
    onSell = () => null
}: {
    onSell?: (isSell: boolean) => void
}) {
    const {
        isActive,
        isLogged,
        isWaiter,
        isOpenOptionsSell,
        setIsOpenOptionsSell,
        isCheckedSell,
        setCheckedSell,
        ref,
        isDealer,
        isCashier
    } = useNTabs(onSell)
    const { business_variables } = useStorage()

    const isEnabledCommission =
        business_variables.find(bv => bv.code === '015')?.value === '1'
    const isEnabledBookings =
        business_variables.find(bv => bv.code === '016')?.value === '1'
    const isEnabledPreOrders =
        business_variables.find(bv => bv.code === '017')?.value === '1'
    const isEnabledDeliveryDealer =
        business_variables.find(bv => bv.code === '018')?.value === '1'
    const isEnabledDelivery =
        business_variables.find(bv => bv.code === '022')?.value === '1'

    return (
        <Flex gap={[2, 2, 2, 4]}>
            {isLogged && !isWaiter() && !isDealer() && (
                <Flex {...isActive('/quick_sale')}>
                    <Icon as={CashIcon} fill="inherit" mr="1" fontSize="lg" />
                    <Box display={displayLabels}>Venta rápida</Box>
                </Flex>
            )}
            <Flex {...isActive('/by_tables')}>
                <Icon as={Table} fill="inherit" mr="1" fontSize="lg" />
                <Box display={displayLabels}>Mesas</Box>
            </Flex>
            {isEnabledDelivery && isLogged && !isWaiter() && !isDealer() && (
                <Flex {...isActive('/deliveries')}>
                    <Icon as={Delivery} fill="inherit" mr="1" fontSize="lg" />
                    <Box display={displayLabels}>Delivery</Box>
                </Flex>
            )}

            {isEnabledDeliveryDealer &&
                isLogged &&
                !isWaiter() &&
                !isCashier() && (
                    <Flex {...isActive('/order_status')}>
                        <Icon as={Grid} fill="inherit" mr="1" fontSize="lg" />
                        <Box display={displayLabels}>Entregas</Box>
                    </Flex>
                )}
            {isEnabledBookings && isEnabledPreOrders && (
                <Flex {...isActive('/pre_orders')}>
                    <Icon as={Grid} fill="inherit" mr="1" fontSize="lg" />
                    <Box display={displayLabels}>Pre-pedidos</Box>
                </Flex>
            )}
            {isLogged && !isWaiter() && !isDealer() && (
                <Flex {...isActive('/cash_register')}>
                    <Icon
                        as={CashRegister}
                        mr="1"
                        fontSize="lg"
                        fill="inherit"
                    />
                    <Box display={displayLabels}>Caja</Box>
                </Flex>
            )}
            {isLogged && !isWaiter() && !isDealer() && (
                <Flex
                    {...isActive('/sales')}
                    position="relative"
                    onContextMenu={() => setIsOpenOptionsSell(true)}
                >
                    <Icon
                        as={Description}
                        mr="1"
                        fontSize="lg"
                        fill="inherit"
                    />
                    <Box display={displayLabels}>Ventas</Box>
                    <Box
                        position="absolute"
                        bg="white"
                        top="10"
                        shadow="md"
                        borderRadius="md"
                        zIndex="10"
                        overflow="hidden"
                        ref={ref}
                        display={isOpenOptionsSell ? 'auto' : 'none'}
                    >
                        <Text
                            color={colors.dark}
                            fontSize="xs"
                            p="2"
                            display="flex"
                            justifyContent="space-between"
                            cursor="pointer"
                            whiteSpace="nowrap"
                            as="label"
                            _hover={{ bg: 'gray.50' }}
                        >
                            Todas las ventas
                            <Checkbox
                                ml={3}
                                size="sm"
                                isChecked={isCheckedSell}
                                onChange={setCheckedSell}
                            />
                        </Text>
                    </Box>
                </Flex>
            )}
            {isEnabledCommission && isLogged && !isDealer() && !isWaiter() && (
                <Flex {...isActive('/pay_commissions')}>
                    <Icon as={CashIcon} fill="inherit" mr="1" fontSize="lg" />
                    <Box display={displayLabels}>Reservas</Box>
                </Flex>
            )}
        </Flex>
    )
}
