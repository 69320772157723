import { useToast } from '@chakra-ui/react'
import { ChangeEvent, FormEvent, useState } from 'react'
import { get, post, put } from '../../lib/http'
import val from '../../lib/val'
import Client from '../../models/client'
import { CreateUser, FormBody } from './types'

export default function useSubmit(
    onDone: (client: Client) => void,
    client?: Client
) {
    const toast = useToast()
    const [isOpen, setOpen] = useState(false)
    const [errorHelper, setErrorHelper] = useState<
        Partial<Record<keyof FormBody, string>>
    >({})
    const [isLoading, setLoading] = useState(false)
    const [form, setForm] = useState({
        number: '',
        firstname: '',
        lastname: '',
        business_name: '',
        address: ''
    })

    const handleSearchSunat = async (type_document: string, number: string) => {
        const result = await get<any>(
            `/api/sunat_vouchers/document?type_document_id=${type_document}&numero=${number}`
        )
        if (result.error) {
            setErrorHelper({ number: result.error.message })
            return
        }
        setForm({
            ...form,
            ...(type_document === '1' && {
                firstname: result.data.result.result.nombres,
                lastname:
                    result.data.result.result.apellidoPaterno +
                    ' ' +
                    result.data.result.result.apellidoMaterno
            }),
            ...(type_document === '2' && {
                business_name: result.data.result.result.nombre,
                address: result.data.result.result.direccion
            })
        })
    }

    const handleChangeForm = (e: any) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
        const name = e.target.name as keyof FormBody
        if (errorHelper[name]) {
            setErrorHelper({
                ...errorHelper,
                [name]: ''
            })
        }
    }

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const formElem = ev.target as typeof ev.target &
            Record<keyof FormBody, { value: string }>

        const type_identification_document_id = parseInt(
            formElem.type_identification_document_id.value
        )

        const body: CreateUser = {
            number: formElem.number.value || form.number,
            type_identification_document_id,
            address: formElem.address.value,
            phones: [formElem.phone.value],
            email: formElem.email.value
        }

        if (type_identification_document_id !== 2) {
            body.firstname = formElem.firstname.value || form.firstname
            body.lastname = formElem.lastname.value || form.lastname
        } else {
            body.business_name =
                formElem.business_name.value || form.business_name
        }

        const v = val(body)
        if (body.type_identification_document_id !== 2) {
            v.firstname.isEmpty('El nombre es requerido')
            v.lastname.isEmpty('El apellido es requerido')
        } else {
            v.business_name.isEmpty('La razón social es requerida')
        }
        v.number.isEmpty('El número de documento es requerido')
        //v.address.isEmpty('La dirección es requerida')
        if (body.email) {
            v.email.email('El correo no es válido')
        }

        const result = v.runtest()
        if (result) {
            setErrorHelper(result)
            return
        }
        setLoading(true)
        const method = client ? put : post
        const restPath = client ? `/${client.id}` : ''
        const { error, data } = await method<Client>(
            `/api/clients${restPath}`,
            body
        )
        if (error) {
            setErrorHelper(error.errors)
        } else {
            toast({
                title: 'El cliente se ha creado',
                status: 'success'
            })
            onDone(data.result)
            setOpen(false)
        }
        setLoading(false)
    }

    function handleChange(
        ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const name = ev.target.name as keyof FormBody
        if (errorHelper[name]) {
            setErrorHelper({
                ...errorHelper,
                [name]: ''
            })
        }
    }

    function openHandler() {
        setForm({
            number: client?.number || '',
            firstname: client?.firstname || '',
            lastname: client?.lastname || '',
            business_name: client?.business_name || '',
            address: client?.address || ''
        })
        setErrorHelper({})
        setOpen(true)
    }

    return {
        submitHandler,
        errorHelper,
        setErrorHelper,
        isLoading,
        isOpen,
        setOpen,
        openHandler,
        handleChange,
        form,
        handleChangeForm,
        handleSearchSunat
    }
}
