import { ChangeEvent, useState } from 'react'
import { ContentFormProps } from '../types'
import Counter from './Counter'
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea
} from '@chakra-ui/react'
import ThumbUp from '../../../../../components/Icon/ThumbUp'
import ErrorHelper from '../../../../../components/ErrorHelper'
import useSession from '../../../../../hooks/useSession'

export default function ContentForm({
    onSubmit,
    defaultQuantity,
    description,
    onChangeDescription,
    defaultUnitPrice,
    onOpenPreference,
    helperPrice,
    onChangePrice,
    min
}: ContentFormProps) {
    const { isWaiter, isLogged } = useSession()
    const [price, setPrice] = useState(() => defaultUnitPrice.toString())
    const isWaiter_ = isWaiter() || !isLogged

    function changePriceHandler(ev: ChangeEvent<HTMLInputElement>) {
        const value = ev.target.value
        const regExp = new RegExp('^[0-9]+([.]{1}[0-9]{1,2})?$')
        if (
            value === '' ||
            regExp.test(value) ||
            (value.split('.').length === 2 && value.split('.')[1] === '')
        ) {
            setPrice(value)
            onChangePrice()
        }
    }

    return (
        <>
            <form id="form-edit-item" onSubmit={onSubmit}>
                <Counter defaultQuantity={defaultQuantity} min={min} />
                <FormControl
                    mb={4}
                    alignItems="flex-end"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Button
                        size="sm"
                        bg="orange"
                        color="white"
                        leftIcon={<ThumbUp fill="white" />}
                        onClick={onOpenPreference}
                        w="auto"
                    >
                        Preferencias
                    </Button>
                    <Textarea
                        w="full"
                        name="description"
                        placeholder="Ingresar información adicional"
                        value={description}
                        onChange={ev => onChangeDescription(ev.target.value)}
                        autoFocus
                        //isDisabled={isDisable_description && storagePage.editOrderTable}
                    />
                </FormControl>
                {!isWaiter_ && (
                    <FormControl>
                        <FormLabel>Precio unitario (S/)</FormLabel>
                        <Input
                            name="price"
                            placeholder="Ingresar precio unitario"
                            onChange={changePriceHandler}
                            isInvalid={!!helperPrice}
                            value={price}
                        />
                        <ErrorHelper helper={helperPrice} />
                    </FormControl>
                )}
            </form>
        </>
    )
}
