import { ReactNode } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Link from './Link'
import LogoBox from './LogoBox'
import dataLinks from './dataLinks'
import useStorage from '../../hooks/useStorage'

export default function Sidebar({ children }: { children: ReactNode }) {
    const { business_variables } = useStorage()
    const enable_warehouse = business_variables.find(
        v => v.code === '006'
    )?.value

    const isEnabledReservation =
        business_variables.find(v => v.code === '016')?.value === '1'

    const isEnabledElectronicReceipts =
        business_variables.find(v => v.code === '002')?.value === '1'

    let linksList = dataLinks
    if (!isEnabledReservation) {
        linksList = dataLinks.filter(
            l => l.path !== '/bookings' && l.path !== '/tourist_groups'
        )
    }
    if (!isEnabledElectronicReceipts) {
        linksList = linksList.filter(l => l.path !== '/generateVoucher')
    }

    const enable_credit_sales = business_variables.find(
        v => v.code === '021'
    )?.value

    return (
        <Box w="full" bg="base-2" minHeight="100vh">
            <Flex>
                <Box
                    w={{
                        base: '0px',
                        lg: '232px',
                        xl: '250px'
                    }}
                    bg="black"
                    color="white"
                    minH="100vh"
                    display={{
                        base: 'none',
                        md: 'none',
                        lg: 'block'
                    }}
                >
                    <Flex direction="column" py={8}>
                        <LogoBox />
                        {linksList
                            .filter(
                                r =>
                                    !(
                                        (r.path == '/warehouses' &&
                                            enable_warehouse === '0') ||
                                        (r.path == '/pendingAccounts' &&
                                            enable_credit_sales === '0')
                                    )
                            ) //Si el modulo Almacen esta desactivado no se muestra
                            .map(route => (
                                <Link
                                    key={route.path}
                                    label={route.label}
                                    Icon={route.Icon}
                                    path={route.path}
                                />
                            ))}
                    </Flex>
                </Box>
                <Box
                    w={{
                        base: '100vw',
                        lg: 'calc(100vw - 232px)',
                        xl: 'calc(100vw - 250px)'
                    }}
                    px={{
                        xl: 12,
                        lg: 8,
                        md: '5',
                        base: '4'
                    }}
                    py={6}
                >
                    {children}
                </Box>
            </Flex>
        </Box>
    )
}
